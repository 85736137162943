import React from 'react'
import Link from 'gatsby-link'
import logo from './img/logo.png'; // Tell Webpack this JS file uses this image
import logoccbysa from './img/cc-by-sa.svg'; // Tell Webpack this JS file uses this image
import './style.scss'

class SiteFooter extends React.Component {
  render() {
    return (
      <footer className="footer" role="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-4"style={{fontSize:0.9+'em'}}>
              <a className="cc-link" href="https://creativecommons.org/licenses/by-sa/3.0/fr/" title="Voir le texte de la licence">
                <img className="cc-img" src={logoccbysa} alt="logo creative commons BY SA" />
              </a>
              Les contenus de ce site sont publiés<br />
              sous la licence libre CC BY SA.<br />
              Vous pouvez les utiliser en citant la source.
            </div>

            <div className="col-md-4 offset-1" style={{fontSize:0.9+'em'}}>
  
              <ul className="menu">
                <li>
                  <Link to="/contact">
                  Contact
                  </Link>
                </li>
                <li>
                  <Link to="/mentions-legales">
                    Mentions légales
                  </Link>
                </li>
                <li><a href="https://www.etw-france.org/les-centres-en-france/la-ferme-du-plessis/" title="Le site du mouvement Colibris">La ferme du Plessis</a></li>
                <li>
                  <Link to="/participer">
                    Nous soutenir
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 text-right">
              <Link to="/">
                <img className="logo-right" src={logo} alt="logo droite" />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default SiteFooter
