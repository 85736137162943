import React from 'react'
//import LanguageSelector from '../LanguageSelector';
import './style.scss'

class PlessisNavi extends React.Component {
  render() {
      return (
        <>
          <div id="archipel-section">
            <div id="archipel" className="archipel">
              <div className="archipel__wrapper">
                <div className="other-list">
                  <ul className="list-unstyled">
                    <li className="first"><a href="http://amma-wiki.org/ecohameauduplessis/wakka.php?wiki=PagePrincipale">le wiki du projet</a></li>
                    {/* <li className="last"><LanguageSelector /></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
}

export default PlessisNavi
